.politicaContainer {
  background-image: url('../../../assets/images/logo-bg.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: contain;
}

.logoScyggz {
  width: 300px;
}