@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Raleway&display=swap");

body {
  margin: 0;
  font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

.buttonWhite {
  font-family: open sans, Arial, Helvetica, sans-serif;
  border: #999900 1px solid;
  border-radius: 16px;
  text-align: center;
  font-size: 20px;
  letter-spacing: normal;
  line-height: 28px;
  color: #999900 !important;
  background-color: #fff;
  margin-left: 10px;
  padding: 6px 12px;
}

.buttonGreen {
  font-family: open sans, Arial, Helvetica, sans-serif;
  border: #999900 1px solid;
  border-radius: 16px;
  text-align: center;
  font-size: 20px;
  letter-spacing: normal;
  line-height: 28px;
  color: #fff;
  background-color: #999900;
  margin-left: 10px;
  padding: 6px 12px;
}

.buttonDarkGreen {
  font-weight: 400;
  background-color: #666600;
  color: #fff;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
}

.title {
  font-size: 38px;
  line-height: 57px;
  text-align: left;
  letter-spacing: normal;
  font-family: Raleway, Arial, Helvetica, sans-serif;
  color: #414141;
  font-weight: normal;
}

.buttonWhiteHome {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666666;
  height: 68px;
  border: #999900 1px solid;
  border-radius: 16px;
  font-size: 22px;
  width: 100%;

  font-family: open sans, Arial, Helvetica, sans-serif;
  font-size: 22px;
  letter-spacing: normal;
  line-height: 26px;
  background-color: #fff;
  margin: 10px 10px;
  padding: 6px 12px;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .buttonWhiteHome {
    font-size: 17px !important;
  }
}

section.page-header {
  position: relative;
  padding: 50px 0 50px 0;
  border-top: 0;
  margin-top: 0;
  margin-bottom: 0 !important;
  background-color: rgba(0, 0, 0, 0.05);
  border-bottom: rgba(0, 0, 0, 0.05) 1px solid;
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s;
}

page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eee;
}

section.page-header.page-header-xs {
  padding: 20px 0 20px 0;
}

section.page-header h1 {
  margin: 0;
  padding: 0;
  font-size: 26px;
  font-weight: 300;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

.text-green {
  color: #8ab933;
}

.text-green-strong {
  color: #666600 !important;
}

.background-strong-green {
  background-color: #4d4d00;
}

.background-light-green {
  background-color: #666600;
}

.cursorPointer {
  cursor: pointer;
}

.disabledButton {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

.ButtonPerfisUser {
  max-width: 400px;
  width: 12vw;
  min-width: 250px;
  border: rgba(0, 0, 0, 0.1) 1px solid;
  margin: 20px 10px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 10px;
  background-color: #8ab93355;
  padding: 25px 20px 20px 20px;
  font-size: 20px;
  line-height: 20px;
  color: #fff;
}

.bottomGreenLine {
  border-bottom: #666600 1px solid;
}

.sideBarDropdown .dropdown-menu {
  background-color: #88b632 !important;
  position: relative !important;
  transform: translate(0px, 0px) !important;
}

a:hover {
  color: #8ab933;
}

.background-color-spinoff {
  background-color: #999900 !important;
}

.border-color-spinoff {
  border-color: #999900 !important;
}

.color-spinoff {
  color: #999900 !important;
}

.background-light-green {
  background-color: #fcfce3 !important;
}

.background-spinoff {
  background-color: #999900 !important;
}

.background-spinoff {
  background-color: #999900 !important;
}

.cor-ResidentesEspecialistas {
  background-color: #dcdcbc !important;
}

.cor-Cientista {
  background-color: #c2d6d6 !important;
}

.cor-Hub {
  background-color: #33334d !important;
}

.cor-InvestidorAnjo {
  background-color: #023668 !important;
}

.cor-Advogado {
  background-color: #dcdcbc !important;
}

.cor-Empreendedor {
  background-color: #669 !important;
}

.cor-Startup {
  background-color: #4c0013 !important;
}

.cor-Empresas {
  background-color: #106955 !important;
}

.cor-VentureCapital {
  background-color: #101439 !important;
}

.cor-Nit {
  background-color: #dce4e9 !important;
}

.fogo-card {
  position: absolute;
  top: 10%;
  left: 42%;
  margin-bottom: 20px;
  width: 8px;
  height: 11px;
  opacity: 0.4;
}

.circle-year {
  width: 50px;
  height: 50px;
  position: relative;
  border-radius: 50%;
  font-size: 22px;
  font-weight: bold;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #fff;
  line-height: 50px;
}

.Number-badge-card {
  position: relative;
  font-size: 22px;
  font-weight: bold;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #fff;
  margin-bottom: -20px;
  /*margin-left: 3px;*/
  opacity: 0.6;
}

.Data-badge-card {
  font-size: 11px;
  font-weight: bold;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #999900;
  margin-top: 54%;
  position: relative;
}

.userImg {
  border-radius: 50%;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 180px;
  width: 180px;
}

.textBlack {
  color: #111111;
}

.line-height-30 {
  line-height: 30px;
}

.divider {
  position: relative;
  display: block;
  min-height: 20px;
}

.divider.divider-center {
  text-align: center;
}

.divider.divider-center:before {
  left: 0 !important;
  right: 50%;
  margin-right: 20px;
  content: "";
  position: absolute;
  top: 8px;
  height: 0;
  border-top: 1px solid #ddd;
}

.divider:after {
  content: "";
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
  height: 0;
  border-top: 1px solid #ddd;
}

.divider.divider-center:after {
  left: 50% !important;
  right: 0;
  margin-left: 20px;
}

div.divider.divider-border svg {
  line-height: 40px;
  margin-top: -5px;
  text-align: center;
  z-index: 1;
  border: #ddd 1px solid;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  padding: 5px;
}

div.divider svg {
  line-height: 1;
  color: #ccc;
  font-size: 18px;
}

.buttonShadow {
  background-color: #999900;
  line-height: 26px;
  position: relative;
  height: 40px;
  border-bottom: 3px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 3px;
}

.buttonShadow:hover {
  opacity: 0.9;
}

.buttonVerTodos {
  width: 70%;
  background-color: #f0f5f5;
  border-color: #999900;
  font-weight: bold !important;
  color: #999900;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin: 3px 0px 30px;
}

.removeArrowDropdown .dropdown-toggle::after {
  display: none !important;
}

.removeCaret::after,
.removeCaret button::after {
  display: none !important;
}

.headerNavDropdown .dropdown-menu {
  margin: 0px !important;
}

.checkboxDarkGreen {
  border: 1px solid #525200;
  border-color: #525200;
}

.checkboxDarkGreen:checked {
  background-color: #525200;
  border-color: #525200;
}

.checkboxDarkGreen:focus {
  border-color: unset;
  outline: 0;
  box-shadow: 0 0;
}

/* CSS MOBILE */
input.imputMobile {
  border: none;
  border-bottom: 3px #666600 solid;
  width: 100%;
  background: #fff;
  border-bottom-left-radius: 8px;
  padding-left: 10px;
}

.imputMobile {
  border: none;
  border-bottom: 3px #666600 solid;
  width: 100%;
  background: #fff;
  border-bottom-left-radius: 8px;
  padding-left: 10px;
}

input.imputMobile:before {
  display: block;
  content: " /";
  color: red;
  width: 200px;
  height: 200px;
}

.imputMobile:focus {
  outline: none;
}

.react-select-container {
  border: none;
  border-bottom: 3px #666600 solid;
  display: flex;
  background: #fff;
  border-bottom-left-radius: 8px;
  text-align: left;
  width: 100%;
  box-shadow: none !important;
  outline: none !important;
}

.react-select-container.is-invalid {
  outline: none !important;
  box-shadow: none !important;
  border-color: #dc3545 !important;
}

.txt-ellipses {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.active.btn-perfil button:first-child {
  background-color: #999900 !important;
  color: #fff !important;
}

.backgroundDefault {
  background-color: #e2e2cc;
}

.cursor-pointer {
  cursor: pointer;
}