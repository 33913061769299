.button {
  border: #525200 1px solid;
  background-color: #99990025;
  color: #525200;
}

.button:hover,
.button:focus {
  border: #525200 1px solid;
  background-color: #99990045;
  color: #525200;
}
