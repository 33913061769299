.sizeSM {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.sizeMD {
  width: 5rem;
  height: 5rem;
  object-fit: cover;
}

.sizeLG {
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.sizeXl {
  width: 245px;
  height: 245px;
  object-fit: cover;
}