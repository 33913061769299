.footer {
  bottom: 0px;
  position: relative;
  width: 100%;
  color: rgba(255, 255, 255, 0.6);

  background: #666600 !important;
  background: -moz-linear-gradient(top, #333300 0%, #666600 100%);
  background: -webkit-gradient
    (
      linear,
      left top,
      left bottom,
      color-stop(0%, #1a1d2b),
      color-stop (100%, #666600)
    );
  background: -webkit-linear-gradient(top, #333300 0%, #666600 100%);
  background: -o-linear-gradient(top, #333300 0%, #666600 100%);
  background: -ms-linear-gradient(top, #333300 0%, #666600 100%);
  background: linear-gradient(to bottom, #333300 0%, #666600 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#333300', endColorstr='#666600', GradientType=0);
}

footer a {
  color: rgba(255, 255, 255, 0.6);
}

footer a:hover {
  color: rgba(255, 255, 255, 0.9);
}

.footer > .copyright {
  background-color: #4d4d00 !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  font-size: 13px;
  display: block;
}

.copyRight {
  font-size: 13px;
}

.footerMenu {
  min-height: 300px;
}

.h4footer {
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.5;
}
.logoFooter {
  position: relative;
  right: 60px;
}
