.checkbox {
  border: 1px solid 525200;
  border-color: #525200;
}
.checkbox:checked {
  background-color: #525200;
  border-color: #525200;
}
.checkbox:focus {
  border-color: unset;
  outline: 0;
  box-shadow: 0 0;
}
