.button {
  border: #59ba41 1px solid;
  background-color: #525200;
  color: #fff;
}

.button:hover,
.button:focus {
  border: #59ba41 1px solid;
  background-color: #525200f0;
  color: #ffffffee;
}
